<template>
    <button type="button" v-if="info.handler" class="global-btn-generic" @click="() => info.handler?.()">
        <span
            v-if="info.icon && (!info.iconPosition || info.iconPosition === 'left')"
            class="global-btn-generic__image"
            ><img :src="info.icon" :alt="info.iconAlt || 'icon'"
        /></span>
        <span
            v-if="info.htmlIcon && info.htmlIconPosition === 'left'"
            class="global-btn-generic__image html"
            v-html="info.htmlIcon"
        ></span>

        <p class="global-btn-generic__text" v-html="info.text"></p>

        <span v-if="info.icon && info.iconPosition === 'right'" class="global-btn-generic__image"
            ><img :src="info.icon" :alt="info.iconAlt || 'icon'"
        /></span>
        <span
            v-if="info.htmlIcon && info.htmlIconPosition === 'right'"
            class="global-btn-generic__image html"
            v-html="info.htmlIcon"
        ></span>

        <img v-if="loading" :src="$assets.white.loading" height="17" width="17" alt="icon" class="spin" />
    </button>
    <NuxtLink no-prefetch v-else class="global-btn-generic" rel="follow" :to="info.link">
        <span
            v-if="info.icon && (!info.iconPosition || info.iconPosition === 'left')"
            class="global-btn-generic__image"
            ><img :src="info.icon" :alt="info.iconAlt || 'icon'"
        /></span>
        <span
            v-if="info.htmlIcon && info.htmlIconPosition === 'left'"
            class="global-btn-generic__image html"
            v-html="info.htmlIcon"
        ></span>

        <p class="global-btn-generic__text" v-html="info.text"></p>

        <span v-if="info.icon && info.iconPosition === 'right'" class="global-btn-generic__image"
            ><img :src="info.icon" :alt="info.iconAlt || 'icon'"
        /></span>
        <span
            v-if="info.htmlIcon && info.htmlIconPosition === 'right'"
            class="global-btn-generic__image html"
            v-html="info.htmlIcon"
        ></span>
    </NuxtLink>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'

export default defineComponent({
    name: 'ButtonGeneric',
    props: {
        loading: { type: Boolean, default: false },
        info: {
            type: Object as PropType<{
                text: string
                link?: string
                handler?: Function
                icon?: string
                iconPosition?: 'left' | 'right'
                htmlIcon?: string
                htmlIconPosition?: 'left' | 'right'
                iconAlt?: string
            }>,
            required: true,
        },
    },
})
</script>

<style lang="postcss">
.global-btn-generic {
    @apply flex h-10 items-center justify-center space-x-3 rounded-lg border px-5;
    &__image {
        @apply block h-4 w-4;
        img {
            @apply h-full w-full object-contain;
        }
        &.html {
            @apply h-auto w-auto;
        }
    }
}
</style>
